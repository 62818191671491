* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  padding-right: 0 !important;
  font-family: 'Poppins', sans-serif;
  color: var(--base03);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  background-color: var(--base-white-bg);
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;
}

/* ----------pagination----------- */
.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  border-color: #ee7a61 !important;
  border-radius: 50%;
  color: white;
  background: #ee7a61;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ee7a61;
}
.ant-tabs .ant-tabs-ink-bar {
  background: #ee7a61;
}
.ant-tabs .ant-tabs-tab:hover {
  color: #ee7a61;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

select:focus-visible,
input:focus-visible {
  outline-offset: 0px;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Colors Variables */
:root {
  --base00: #e7407d;
  --base01: #404040;
  --base02: #9f9f9f;
  --border: #e8e8e8;
  --black: #000000;
  --white: #ffffff;
  --primarygradient: linear-gradient(90deg, #e7407d 0%, #ef805f 100%);
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

.btn-primary {
  min-width: 200px;
  padding: 10px;
  height: 50px;
  border-radius: 50px;
  color: white;
  font-size: 16px;
  text-transform: capitalize;
  background: var(--primarygradient);
  border: 1px solid var(--base00);
}

.btn-primary:hover {
  background: transparent;
  border-color: var(--base00) !important;
  color: var(--base00) !important;
}

.btn-primary:focus {
  background: transparent !important;
  box-shadow: 0px 2px 1px var(--base00);
}

button.btn.btn-secondary {
  background: #ef8168;
  border: none;
}

button {
  transition: 0.1s ease;
  cursor: pointer;
}

.form-control::placeholder {
  color: var(--form-placeholder);
}

.form-control {
  color: var(--base03);
  padding: 10px;
  border: 1px solid var(--border);
  color: #404040;
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  height: 50px;
}

.form-control:focus,
.form-control:focus-visible {
  background: var(--base-white) !important;
  border: 1px solid #363f72 !important;
  box-shadow: 0px 1px 2px rgb(62 71 132 / 5%), 0px 0px 0px 4px #eaecf5 !important;
}

.DashboardPageLayout {
  position: relative;
}

.DashboardPageLayout .Sidebarlayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100dvh;
  border-right: 1px solid #f1f1f1;
  padding: 40px 50px 40px 50px;
  background-color: var(--white);
  transition: all 0.4s ease-in-out;
}

.DashboardPageLayout .Sidebarlayout .navbarbrand {
  margin-bottom: 40px;
}

.sidebarprofile-sec h5.username {
  margin: 12px 0 0 0;
  font-size: 18px;
  font-weight: 600;
  word-wrap: break-word;
}

.sidebarprofile-sec {
  margin-bottom: 50px;
}

.Sidebarlayout .mainnavbar {
  display: block;
}

.Sidebarlayout .mainnavbar .navbar-title {
  color: #9f9f9f;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  word-wrap: break-word;
  margin-bottom: 34px;
}

/* .Sidebarlayout .logoutbar {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    padding: 0 50px;
    height: auto;
} */
.logoutbar {
  margin-top: 100px;
}

.DashboardPageLayout .Sidebarlayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100dvh;
  border-right: 1px solid #f1f1f1;
  padding: 40px 25px 40px 80px;
  background-color: var(--white);
  overflow-y: auto;
}

/* ::-webkit-scrollbar{
    width: 10px;
    background-color: var(--base02);
}
::-webkit-scrollbar-button{
    width: 10px;
    height: 10px;
    background-color: red;
} */
.Sidebarlayout .mainnavbar .navbar-nav {
  display: block;
}

.Sidebarlayout .mainnavbar a {
  color: #9f9f9f;
  font-size: 18px;
  font-weight: 500;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  margin-bottom: 24px;
  transition: 0.3s ease;
}

.mainwrapper {
  min-height: 100vh;
  background: var(--white);
  padding-left: 320px;
}

.Sidebarlayout .mainnavbar .navbar-nav a.active,
.Sidebarlayout .mainnavbar a:hover {
  color: var(--base01);
}

.bodywrapper {
  padding: 40px;
  max-width: 704px;
}

.Sidebarlayout .mainnavbar a svg {
  font-size: 24px;
}

.title-with-backbtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  margin-bottom: 50px;
}

.backbtn {
  border: none;
  padding: 0;
  background: transparent;
  font-size: 30px;
}

.title-with-backbtn h4 {
  margin: 0;
  color: var(--base01);
  font-size: 22px;
  font-weight: 600;
  word-wrap: break-word;
}

.profile-picture-bar {
  text-align: center;
  margin-bottom: 40px;
}

.profile-image-box {
  max-width: 100px;
  margin: 0 auto;
  position: relative;
}

.profile-image-box .imageuplaod-area img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 50%;
}

.profile-image-box .plusbtn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primarygradient);
  border-radius: 50px;
  color: #ffffff;
  position: absolute;
  right: 5px;
  bottom: 5px;
  transform: translate(0px, 0px);
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
}

.profile-image-box .plusbtn input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.profile-image-box .imageuplaod-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #f1f1f1;
  width: 100px;
  height: 100px;
}

.Accountbar form {
  position: relative;
}

/* The custom-radio-box */
.custom-radio-box {
  display: block;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.form-radio-bar {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.custom-radio-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.custom-radio-box .checkmark {
  min-width: 202px;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 16px;
  color: var(--border);
  border: 1px solid var(--border);
  text-transform: capitalize;
}

.custom-radio-box input:checked ~ .checkmark {
  border-color: var(--base00);
  color: var(--base00);
}

/* Show the indicator (dot/circle) when checked */

/* Style the indicator (dot/circle) */
.custom-radio-box .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.formninputbar {
  position: relative;
  margin-bottom: 20px;
}

.formninputbar .form-label {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
}

.input-date-bar .react-datepicker-wrapper {
  width: 100%;
  position: relative;
}

.input-date-bar span.input-group-text {
  position: absolute;
  right: 0;
  padding: 0;
  background: transparent;
  border: none;
  top: 50%;
  right: 10px;
  transform: translate(0px, -50%);
  z-index: 1;
}

.input-date-bar .react-datepicker-wrapper input.form-control {
  padding-right: 40px;
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  height: 50px;
  position: relative;
  z-index: 2;
  background: transparent;
}

.inputbar-btnpink {
  margin-top: 50px;
}

.inputbar-radiocheck .form-label {
  color: #404040;
}

.formtitle {
  color: #404040;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.aboutform .formtitle {
  margin-bottom: 50px;
}

.form-toolbar {
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  border-radius: 12px;
  margin-bottom: 16px;
}

.form-toolbar h4 {
  margin: 0;
  color: #404040;
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
}

.form-toolbar ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.form-toolbar ul button {
  background: white;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: var(--base00);
}

.form-toolbar ul button.active {
  background: linear-gradient(90deg, #e7407d 0%, #ef805f 100%);
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.07);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
}

.form-secondry-title {
  color: #404040;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.formninputbar .textgray {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 5px;
}

.formninputbar textarea {
  min-height: 90px;
  background: #f1f1f1;
}

/* -----------method-response------------- */

.top-text {
  color: #404040;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  margin-top: 20px;
}

.switch-box {
  display: flex;
  align-items: center;
  max-width: 296px;
  padding: 14px 16px;
  background: #f9f9f9;
  border-radius: 4px;
  justify-content: space-between;
}

.toggle-form {
  margin-top: 50px;
}

.same-day {
  color: #404040;
  font-size: 13px;
}

.form-switch .form-check-input {
  width: 2.8em;
  height: 1.6em;
  border: 1px solid #dfdfdf;
}

.form-check-input:checked {
  background-color: #ff5f99;
  border-color: transparent;
}

.form-check-input:focus {
  box-shadow: none;
}

.day-selector {
  margin-top: 40px;
}

.day-selector .day {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(238, 122, 97, 1);
  color: white;
  border-radius: 50%;
  cursor: pointer;
}

.day-selector .day p {
  font-size: 12px;
}

.Select-day-pattern-inactive .day-selector {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}

.time-boxes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 296px;
  column-gap: 30px;
}

.time-boxes input {
  padding: 10px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  font-size: 12px;
}

.Select-day-pattern .day-selector {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.Select-day-pattern .day-selector .day.inactive {
  background: #f9f9f9;
  color: #9f9f9f;
}

/* ------------------------------- payment----------------------------- */
.payment-management .form-control {
  height: 40px;
  background: #f9f9f9;
}

.payment-cards-box {
  margin: 40px 0;
}

.payment-card {
  display: flex;
  min-height: 146px;
  width: auto;
  padding: 14px 16px;
  border: 1px solid transparent;
  column-gap: 12px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.payment-card input:checked .payment-card {
  border: 1px solid #ee7a61;
}

.payment-card h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  color: #404040;
}

.payment-card p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #404040;
}

.monthly {
  padding: 2px 5px;
  background: var(--primarygradient);
  text-transform: capitalize;
  color: white;
  font-size: 12px;
  font-weight: 400;
}

.price {
  font-size: 16px;
  font-weight: 700;
  color: #404040;
}

.upgrade-link {
  color: #ee7a61;
  font-weight: 600;
  text-decoration: underline;
  font-size: 16px;
}

.borderbg {
  border: 1px solid #ee7a61;
  background: white;
}

.new-payment-method {
  background: transparent;
  border: none;
  color: #ee7a61;
  font-size: 14px;
  font-weight: 500;
}

.payment-methods {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input[type='radio'] {
  accent-color: rgb(241, 75, 75);
}

input[type='radio']:checked {
  background-color: white !important;
  color: white !important;
}

.visa-card {
  margin: 20px 0;
  padding: 12px;
  display: flex;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
}

.border {
  border: 1px solid #ee7a61 !important;
}

.card-info {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.card-number p {
  color: #404040;
}

.card-number small {
  color: #9f9f9f;
}

.dots-button {
  padding: 10px;
  background: transparent;
  border: none;
}

.card-payment-box {
  padding: 30px 0;
}

/* --------------------------admin------------------------------- */
.main-page {
  padding: 20px 0;
}

.padding-admin {
  padding: 50px;
  max-width: 1100px;
}

.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.page-name {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.page-name h4 {
  margin-bottom: 0;
}

.back-btn {
  background: white;
  border: none;
}

.import-export {
  display: flex;
  align-items: center;
  column-gap: 22px;
  width: fit-content;
}

.import-export button {
  color: #ee7a61;
  display: flex;
  border: none;
  background: white;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  column-gap: 8px;
}

.top-cards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
}

.info-card {
  padding: 12px 18px;
  flex: 1 1 48%;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  display: flex;
  column-gap: 25px;
}

.card-details {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.card-details .crypto {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.crypto h4 {
  margin-bottom: 0;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #404040;
  white-space: nowrap;
}

.heading-text {
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #9f9f9f;
}

.card-details .percentage {
  padding: 2px 6px;
  border-radius: 100px;
  background: #e7f7ed;
  color: #287648;
}

.card-details a {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ee7a61;
}

.balance {
  margin-top: 8px;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  color: #404040;
}

.chart-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.period-box button,
.period-box .select {
  background: white;
  padding: 11px 14px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #404040;
  transition: 0.4s ease-in-out;
  flex: 1 1 48%;
}
.period-box select {
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #404040;
  transition: 0.4s ease-in-out;
}

.chart {
  padding: 16px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin: 20px 0;
}

.period-box {
  flex: 0 0 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.period-box button {
  display: flex;
  align-items: center;
  column-gap: 14px;
  padding: 13px 14px !important;
}

.period-box button:hover,
.period-box .select:hover {
  background: #d7d7d7;
}
.period-box .select:hover select {
  background: #d7d7d7;
}

/* -------------------------users-------------------- */

.serachbar-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  column-gap: 16px;
}

.searchbar {
  flex: 1 1 80%;
}
.searchbar .autocomplete-root input {
  border: 1px solid #dfdfdf !important;
  border-radius: 0.5rem;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding: 0.5rem !important;
  width: 100%;
}

.filter {
  flex: 1 1 20%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 7px 10px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  color: #9f9f9f;
  column-gap: 8px;
}

.filter select {
  width: 100%;
  border: none;
  color: #9f9f9f;
}

.searchbar .input-group-text,
.serachbar > input {
  background: white;
  font-size: 20px;
  color: #9f9f9f;
  border: 1px solid #dfdfdf;
  border-right: 0;
}

.searchbar .form-control {
  height: 46px;
  color: #9f9f9f;
  border-left: 0;
}
.border-l {
  border: 1px solid #dfdfdf !important;
}

.user-details {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.user-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  color: #404040;
}

.user-mail {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}
table thead {
  border-bottom: 10px solid transparent;
}
table tr th {
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #9f9f9f;
  text-align: center;
  white-space: nowrap;
}
table tr td {
  text-align: center;
}
table tr td:nth-child(2) {
  text-align: center;
}
tbody tr {
  background: #f9f9f9;
  border-bottom: 10px solid white;
  vertical-align: middle;
  height: 57px;
}
tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}
tbody tr td:last-child {
  border-radius: 0 8px 8px 0;
  text-align: center;
}
thead tr th:last-child {
  text-align: center;
}
.red-text {
  color: #ee7a61;
}
.user-img {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
}

/* ------------------------account-page-admin----------------------- */
.heading-spaced {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 2px;
  color: #404040;
  margin-top: 40px;
}
.discount-details {
  padding: 16px 24px;
  border-radius: 8px;
  background: #f9f9f9;
  margin: 30px 0;
}
.card-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  color: #404040;
}
.subscription-info-box {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
}
.subscription-info {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 13px 16px;
  row-gap: 8px;
  white-space: nowrap;
  flex: 1 1 48%;
}
.subscription-info p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #9f9f9f;
}
.subscription-info small {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  color: #9f9f9f;
}
.continue-trial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.continue-trial p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #404040;
}
.buttons-cancel-update {
  display: flex;
  align-items: center !important;
  gap: 26px;
}
.cancel-btn {
  background: none;
  border: none;
  text-decoration: underline;
  color: #404040;
  font-size: 18px;
}
.payment-card:has(input[type='radio']:checked) {
  border: 1px solid #ee7a3c;
  background: white;
}
.visa-card:has(input[type='radio']:checked) {
  border: 1px solid #ee7a3c;
  background: white;
}
/* ---------------mobile nav----------- */
.navmob {
  display: none;
}

.minwidth {
  min-width: 40% !important;
}
.minwidth input {
  border: 1px solid #dfdfdf;
}
.minwidth label {
  color: #707070;
}

.pagination-rule {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 10px 0px;
}
