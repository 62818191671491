@media screen and (max-width:1200px){

}
@media screen and (max-width:1024px){

}
@media screen and (max-width:991px){
    .DashboardPageLayout .Sidebarlayout {
        left: -100%;
        width: 211px;
        padding: 12px 25px;
        z-index: 20;
    }
    .logoutbar{
        margin-top: 22px;
    }
    .Sidebarlayout .mainnavbar a{
        margin-bottom: 15px;
        font-size: 14px;
    }
    .Sidebarlayout .mainnavbar a svg{
        font-size: 20px;
    }
    .navbar-toggler-icon{
        width: 1em !important;
    }
    .navbar-toggler:focus{
        box-shadow: none;
    }
    .sidebaractive .DashboardPageLayout .Sidebarlayout{
        left: 0;
    }
    .mainwrapper {
        padding-left: 0;
    }
    .bodywrapper{
        max-width: 100%;
        padding: 25px;
    }
    .padding-admin{
        max-width: 100%;
    }
    .navmob{
        display: flex;
    }

}
@media screen and (max-width:768px){
    .padding-admin {
        padding: 25px;
    }
    .custom-radio-box .checkmark{
        min-width: 150px;
    }
    .bodywrapper{
        padding: 15px;
    }
    .time-boxes{
        column-gap: 8px;
    }

}
@media screen and (max-width:574px){
    .btn-primary {
        min-width: 106px;
        padding: 7px;
        height: auto;
    }
    .day-selector .day p {
    font-size: 10px;
    }
    .day-selector .day{
        min-width: 35px;
        height: 35px;
    }
    .padding-admin {
        padding: 15px;
    }
    .crypto h4 {
      font-size: 20px;
    }
    .card-details .percentage {
        font-size: 11px;
    }
    .balance{
        font-size: 20px;
    }
    .heading-text {
    font-size: 12px;
    }
    .page-name h4{
        font-size: 18px;
    }
    .import-export{
        column-gap: 8px;
    }
    .import-export button{
        font-size: 12px;
    }
    .custom-radio-box .checkmark {
        min-width: 84px;
        font-size: 12px;
    }

}